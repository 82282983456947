




























































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { HandleDirective } from 'vue-slicksort'

import { NavigationItem } from '../contracts'
import { NavigationList } from './NavigationList.vue'

@Component<NavigationListItem>({
  name: 'NavigationListItem',
  components: { NavigationList },
  directives: { handle: HandleDirective },
  mounted () {
    if (this.openOnStart) {
      this.expanded = true
    }
  }
})
export class NavigationListItem extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public disabled?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public fullWidth!: boolean

  @Prop({ type: Number, required: true })
  public index!: number

  @PropSync('item', { type: Object, required: true })
  public listItem!: NavigationItem

  @Prop({ type: Boolean, required: false, default: false })
  public openOnStart!: boolean

  public expanded: boolean = false

  public Icons = DashmixIconName

  public get count () {
    return this.listItem.children?.length ?? 0
  }

  public addChild () {
    this.expanded = true
    this.$emit('addChild')
  }

  public editItem () {
    this.$emit('edit', { item: this.listItem, path: [this.index] })
  }

  public toggleChildren () {
    this.expanded = !this.expanded
  }
}

export default NavigationListItem
